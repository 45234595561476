.fret-note-container {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.fret-note-circle {
  border-radius: 4px;
  width: 32px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  padding: 2px;
  transition: background-color 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.fret-note-circle:hover {
  background-color: #666;
}

.fret-note-filtered {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgb(67, 114, 214);
  color: #fff;
}

.fret-note-filtered:hover {
  background-color: rgb(7, 64, 187);
}
