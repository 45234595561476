.fret-container {
  position: relative;
  background-color: rgb(235, 232, 226, 0.2);
  margin: 2px;
  flex-shrink: 0;
  min-width: 64px;
}

.fret-inlay-container {
  position: absolute;
  top: 0;
  bottom: 31px; /* height of the fret number div below the fret */
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  z-index: 0;
}
